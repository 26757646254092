<template>
  <div>
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="page-section bg-dark border-bottom-white">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <div class="flex mb-16pt mb-md-0">
              <h2 class="text-white mb-0" style="margin-top: 20%"><b-skeleton width="85%"></b-skeleton></h2>
              <p class="lead text-white-50">
                <b-skeleton width="60%"></b-skeleton>
              </p>
            </div>
          </div>
        </div>

        <div class="page-section bg-alt border-bottom-2">
          <div :class="containerClass">
            <div class="row justify-content-center">
              <b-skeleton type="button" class="ml-2 w-25"></b-skeleton>
              <b-skeleton type="button" class="ml-2 w-15"></b-skeleton>
            </div>
          </div>
        </div>
      </template>
      <div :class="containerClass">
        <div class="backResult my-3 font-weight-bold">
          <router-link :to="{ name: 'public-sch-programs' }" style="text-decoration: underline" class="mr-2">
            <span class="material-icons icon-22pt">navigate_before</span>
          </router-link>
        </div>
        <div
          class="bg-dark employer-profile-bg border-bottom-white overlay overlay--primary-pickled-bluewood"
          :style="`background-image: url('${program.image}');`"
        >
          <div class="overlay__content page-section employer-profile-cover-ol">
            <div
              class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
              :class="containerClass"
            >
              <div class="flex mb-32pt mb-md-0">
                <h2 class="text-white mb-0" style="margin-top: 20%" v-text="program.title" />

                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <p class="lead text-white-50">
                      <b-skeleton width="60%"></b-skeleton>
                    </p>
                  </template>

                  <b-badge
                    variant="primary"
                    style="font-size: 1rem"
                    :style="`background: linear-gradient(30deg, #0803032e 5%, ${get(
                      getCurrentSchool,
                      'primary_color'
                    )} 100%)`"
                  >
                    {{ $t(`programTypeOptions.${program.type}`) }}
                  </b-badge>
                </b-skeleton-wrapper>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-section bg-alt border-bottom-2 justify-content-center">
        <div :class="containerClass">
          <div class="row justify-content-center">
            <b-btn variant="secondary" @click.prevent="openInfoModal" :class="{ 'w-100': windowWidth <= 400 }">
              <i
                :class="get(getCurrentSchool, 'is_chat_enabled') ? 'fas fa-comment-dots' : 'fas fa-envelope'"
                class="mr-1"
              ></i>
              {{ get(getCurrentSchool, 'is_chat_enabled') ? 'Chat With Us' : 'Request Information' }}
            </b-btn>

            <div v-if="isPayAccExists">
              <b-btn
                variant="dark"
                v-if="classes.length > 0"
                @click.prevent="showOfferings"
                :class="windowWidth <= 400 ? 'mt-2 w-100' : 'ml-2'"
                ><i class="fas fa-edit mr-1"></i> Enroll Now
              </b-btn>
              <b-btn
                variant="dark"
                v-else
                @click.prevent="openWaitlistModal"
                :class="windowWidth <= 400 ? 'mt-2 w-100' : 'ml-2'"
                ><i class="fas fa-user-clock mr-1"></i> Join Waitlist
              </b-btn>
            </div>
          </div>
        </div>
      </div>

      <div :class="containerClass">
        <div style="display: center">
          <b-tabs
            nav-class="nav-tabs-card   bg-white px-2"
            content-class=" "
            class="mt-0"
            id="homePageTab"
            @input="onTabsChanged"
            style="scroll-margin-top: 66px; font-size: 16.5px"
            v-model="tabIndex"
          >
            <b-tab title="Overview" class="tab-secondary">
              <template #title>
                <i class="fas fa-info align-self-center" :class="{ 'mx-2': isTabSmallScreen }"></i>
                <strong class="ml-2" v-if="!isTabSmallScreen">Overview</strong>
              </template>

              <div class="page-section">
                <overview-tab :program="program" />
              </div>
            </b-tab>

            <b-tab title="Upcoming Classes" class="tab-secondary" v-if="isPayAccExists">
              <template #title>
                <i class="fas fa-calendar-week align-self-center" :class="{ 'mx-2': isTabSmallScreen }"></i>
                <strong class="ml-2" v-if="!isTabSmallScreen">Upcoming Classes</strong>
              </template>

              <div class="page-section">
                <schedule-tab :are-classes-loading="areClassesLoading" :classes="classes" :program="program" />
              </div>
            </b-tab>

            <b-tab title="Instructors" class="tab-secondary" v-if="isPayAccExists">
              <template #title>
                <i class="fas fa-chalkboard-teacher align-self-center" :class="{ 'mx-2': isTabSmallScreen }"></i>
                <strong class="ml-2" v-if="!isTabSmallScreen">Instructors</strong>
              </template>

              <div class="page-section">
                <instructors-tab :instructors="instructors" :program="program" />
              </div>
            </b-tab>

            <b-tab title="FAQs" class="tab-secondary">
              <template #title>
                <i class="fas fa-question align-self-center" :class="{ 'mx-2': isTabSmallScreen }"></i>
                <strong class="ml-2" v-if="!isTabSmallScreen">FAQs</strong>
              </template>
              <div class="page-section">
                <program-faq-tab :faqs="faqs" />
              </div>
            </b-tab>

            <b-tab title="Sponsorships" class="tab-secondary">
              <template #title>
                <i
                  class="fas fa-graduation-cap align-self-center"
                  :class="{ 'mx-2': isTabSmallScreen, 'nav-tabs-card-scholarship': scholarshipsCount }"
                ></i>
                <strong
                  class="ml-2"
                  :class="{ 'nav-tabs-card-scholarship': scholarshipsCount }"
                  v-if="!isTabSmallScreen"
                >
                  {{ scholarshipsCount ? 'Sponsorships Available' : 'Sponsorships' }}
                </strong>
              </template>

              <div class="page-section">
                <scholarship-tab
                  @totalScholarships="totalScholarships"
                  :are-classes-loading="areClassesLoading"
                  :classes="classes"
                  :program="program"
                />
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-skeleton-wrapper>
    <info-modal :scholarships="scholarships" :program="program" :showModal="showInfoModal" @close="hideInfoModal" />
    <join-waitlist-modal :program="program" :showModal="showWaitlistForm" @close="hideWaitlistModal" />
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import Page from '../../../components/Page.vue';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import OverviewTab from './DetailsTab/OverviewTab.vue';
import ScheduleTab from './DetailsTab/ScheduleTab.vue';
import ScholarshipTab from './DetailsTab/ScholarshipTab.vue';
import InstructorsTab from './DetailsTab/InstructorsTab.vue';
import InfoModal from './InfoModal/InfoModal.vue';
import ProgramFaqTab from './DetailsTab/ProgramFaqTab.vue';
import JoinWaitlistModal from '../../SchoolPortal/Waitlist/JoinWaitlistModal.vue';
import 'vue-swatches/dist/vue-swatches.css';
export default {
  components: {
    OverviewTab,
    ScheduleTab,
    ScholarshipTab,
    InstructorsTab,
    ProgramFaqTab,

    InfoModal,
    JoinWaitlistModal,
  },
  head() {
    return {
      title: `${get(this.program, 'title', 'Program Details')} - ${get(
        this.getCurrentSchool,
        'name',
        'Transition Enroll'
      )}`,
      meta: [
        {
          name: 'keywords',
          content: (get(this.getCurrentSchool, 'meta_tags', []) || []).toString(),
        },
        {
          name: 'description',
          content: get(this.program, 'summary'),
        },
      ],
    };
  },
  extends: Page,
  data() {
    return {
      title: 'Program Detail',
      DefaultAvatar,
      isLoading: false,
      isStatusUpdating: false,
      showWaitlistForm: false,
      isPayAccExists: false,
      user: {
        first_name: '',
        last_name: '',
        avatar_url: '',
        grad_date: '',
      },
      showInfoModal: false,
      program: {},
      classes: [],
      instructors: [],
      faqs: [],
      areClassesLoading: false,
      areInstructorsLoading: false,
      areFaqsLoading: false,
      tabIndex: 0,
      windowWidth: window.innerWidth,
      scholarshipsCount: 0,
      scholarships: [],
    };
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        {
          text: 'Programs',
          to: { name: 'program-page' },
        },
        {
          text: 'Program Detail',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.areClassesLoading || this.areFaqsLoading;
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },

    isMobSmallScreen() {
      return this.windowWidth <= 600;
    },
  },
  watch: {
    getProgramFromSlug: {
      immediate: true,
      deep: true,
      handler(value) {
        if (get(value, 'id')) {
          this.completeHeadTitle = `${get(this.program, 'title', 'Program Details')} - ${get(
            this.getCurrentSchool,
            'name',
            'Transition Enroll'
          )}`;
        }
      },
    },

    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.fetchIsAccExists();
        }
      },
    },
  },
  methods: {
    ...mapActions('program', [
      'getProgramFromSlug',
      'getProgramOfferedClasses',
      'getPublicPrgFaqs',
      'getAllScholarshipList',
    ]),
    ...mapActions('instructor', ['getPublicInstructors']),
    ...mapActions('payoutAcc', ['getSchoolPayAccount']),
    ...mapActions('settings', ['setSettings']),
    get,
    totalScholarships(scholarships) {
      this.scholarshipsCount = scholarships;
    },
    showOfferings() {
      this.tabIndex = 1;
    },
    openInfoModal() {
      this.showInfoModal = true;
    },
    hideInfoModal() {
      this.showInfoModal = false;
    },
    openWaitlistModal() {
      this.showWaitlistForm = true;
    },
    hideWaitlistModal() {
      this.showWaitlistForm = false;
    },

    onTabsChanged() {
      if (!this.firstLoad) {
        document.getElementById('homePageTab').scrollIntoView({ behavior: 'smooth' });
      }
      this.firstLoad = false;
    },
    async fetchClasses(params = {}) {
      this.areClassesLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getProgramOfferedClasses({
        program: this.program.id,

        ...params,
      });
      this.classes = response.data;
      this.areClassesLoading = false;
    },
    async fetchScholarships(params = {}) {
      try {
        const response = await this.getAllScholarshipList({
          program_id: this.program.id,
          is_active: true,
          ...params,
        });

        this.scholarships = response.data;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async fetchIsAccExists() {
      const response = await this.getSchoolPayAccount({
        school: get(this.getCurrentSchool, 'id'),
      });

      this.isPayAccExists = response.data.acc_exist;
    },
    async fetchInstructors(params = {}) {
      this.areInstructorsLoading = true;
      const response = await this.getPublicInstructors({
        program: this.program.id,
        ...params,
      });
      this.instructors = response.data;
      this.areInstructorsLoading = false;
    },
    async fetchFaqs(params = {}) {
      this.areFaqsLoading = true;
      const response = await this.getPublicPrgFaqs({
        program: this.program.id,
        ...params,
      });
      this.faqs = response.data.map((faq) => ({ ...faq, answer: faq.answer.replace(/(?:\r\n|\r|\n)/g, '<br>') }));
      this.areFaqsLoading = false;
    },
    async fetchProgram() {
      this.isLoading = true;
      try {
        const resp = await this.getProgramFromSlug({ slug: this.$route.params.slug });
        this.program = resp;
        this.title = get(this.program, 'title', 'Program Details');
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  async created() {
    this.setSettings({ layout: { layout: 'fixed' } });

    if (this.$route.params.slug) {
      await this.fetchProgram();
      this.fetchClasses();
      this.fetchInstructors();
      this.fetchFaqs();
    }
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  async mounted() {
    if (this.$route.query.class_id || this.$route.query.tab) {
      this.tabIndex = 1;
    }
  },
};
</script>
