<template>
  <b-modal
    ref="paymentPlanModal"
    hide-footer
    title="Payment Schedule"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="lg"
  >
    <div v-if="!get(paymentPlan, 'length')" class="card-body text-center">
      <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
      <h4>No Payment Plan Available</h4>

      <p class="text-muted">Currently there isn't any payment plan available. Please check again later.</p>
    </div>
    <b-table
      v-else
      :fields="paymentPlanTableColumns"
      :items="paymentPlan"
      head-variant="light"
      class="table-nowrap"
      hover
      responsive
      no-local-sorting
    >
      <template #cell(amount)="data">
        <span v-if="data.item.amount_type === PAYMENT_TYPE.DOLLAR_AMOUNT"
          >{{ $n(data.item.amount, 'currency', 'en-US') }} ({{
            $n(computePercentAmount(data.item.amount), { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }}%)
        </span>

        <span v-else
          >{{ $n(computePlanAmount(data.item.amount), 'currency', 'en-US') }} ({{ $n(data.item.amount) }}%)</span
        >
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import { formatDayDate } from '../../../../common/utils';
import { PAYMENT_TYPE } from '../../../../common/constants';
import { get } from 'lodash';

export default {
  components: {},

  props: {
    showModal: { type: Boolean, default: false },
    paymentPlan: { type: Array, default: () => [] },
    programTuition: { type: Number || null },
  },
  data() {
    return {
      PAYMENT_TYPE,
    };
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.paymentPlanModal.show();
      }
    },
  },

  computed: {
    paymentPlanTableColumns() {
      return [
        { key: 'date', label: 'Due Date', formatter: (value) => (value ? formatDayDate(value) : value) },
        { key: 'amount', label: 'Tuition' },
      ];
    },
  },

  methods: {
    get,

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.paymentPlanModal.hide();
      this.hideModal();
    },

    computePlanAmount(percent) {
      return (percent / 100) * (this.programTuition || 0);
    },
    computePercentAmount(amount) {
      return (amount / this.programTuition || 0) * 100;
    },
  },
};
</script>

<style>
</style>
