<template>
  <div class="mb-0pt">
    <div class="page-section">
      <div :class="pageContainer">
        <page-separator
          :title="
            isLoading ? '' : `Showing ${totalPrograms ? `${$n(programsCount)}` : 0} of ${$n(totalPrograms)} Programs`
          "
        />

        <div class="page-section d-flex justify-content-center" v-if="!programs.length && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Programs Found</h4>

              <p class="text-muted">Couldn't find any programs.</p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6" v-for="item in [1, 2, 3]" :key="item">
                <div class="card card-sm card--elevated p-relative">
                  <span class="js-image">
                    <b-skeleton-img width="100%" class="pb-0 mb-0"></b-skeleton-img>
                  </span>

                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex">
                        <a class="card-title" href="#" @click.prevent><b-skeleton width="70%"></b-skeleton></a>
                        <small class="text-50 font-weight-bold mb-4pt"><b-skeleton width="50%"></b-skeleton></small>
                      </div>
                      <a href="#" class="ml-4pt material-icons text-20 card-course__icon-favorite" @click.prevent
                        >more_vert</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <div v-for="program in programs" :key="program.id" class="col-lg-4 col-md-6 col-sm-6">
              <program-card :program="program" :next-class="nextClassObj" />
            </div>
          </div>
          <div class="d-flex justify-content-center" v-if="programsCount < totalPrograms">
            <a>
              <router-link :to="{ name: 'public-sch-programs' }" class="btn btn-primary mb-3">View more</router-link></a
            >
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { mapGetters, mapActions } from 'vuex';

import Page from '@/components/Page.vue';

import { cloneDeep, debounce, get, keyBy } from 'lodash';
import ProgramCard from './ProgramCard.vue';
import { USER_ROLES, PROGRAM_STATUSES, USER_ROLE_TYPES } from '../../../common/constants';
export default {
  components: {
    PageSeparator,
    ProgramCard,
  },
  head() {
    return {
      meta: [
        {
          name: 'keywords',
          content: (get(this.getCurrentSchool, 'meta_tags', []) || []).toString(),
        },
        {
          name: 'description',
          content: get(this.getCurrentSchool, 'pp_home_description'),
        },
      ],
    };
  },
  extends: Page,
  props: {
    pageContainer: { type: String, default: 'container page__container' },
  },
  data() {
    return {
      title: 'Home',

      isLoading: true,
      schoolId: null,
      perPage: 18,
      currentPage: 1,
      totalPrograms: 0,
      ordering: 'title',
      programs: [],
      refPrograms: [],
      typeOptions: [
        { value: 'virtual', text: 'Virtual' },
        { value: 'onsite', text: 'Onsite' },
        { value: 'blended', text: 'Hybrid' },
      ],
      USER_ROLES,
      PROGRAM_STATUSES,
      USER_ROLE_TYPES,
      programsCount: 0,
      searchTerm: '',
      pageFromCount: 0,
      pageToCount: 0,
      nextClasses: [],
      nextClassObj: {},
      areClassesLoading: false,
      areRefLoading: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('Programs'), active: true },
      ];
    },
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },
  watch: {
    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.schoolId = value;
          this.fetchPrograms();

          this.completeHeadTitle = `${get(this.getCurrentSchool, 'name', 'Home')} - ${get(
            this.getCurrentSchool,
            'pp_home_title',
            'Transition Enroll'
          )}`;
        }
      },
    },
  },

  methods: {
    ...mapActions('program', ['getSchoolOfferedPrograms', 'getProgramNextClass', 'getSchoolReferredPrograms']),
    get,
    async fetchNextClass() {
      this.areClassesLoading = true;
      const response = await this.getProgramNextClass({ programs: this.programs.map(cs => cs.id) });
      this.nextClasses = response.data;
      this.nextClassObj = keyBy(response.data, 'program');
      this.areClassesLoading = false;
    },
    async fetchPrograms(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      if (pageNum == 1) {
        await this.fetchReferredPrograms();
      }
      const response = await this.getSchoolOfferedPrograms({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,

        ...(this.searchTerm && { search: this.searchTerm }),
        school: get(this.getCurrentSchool, 'id'),
        ...params,
      });
      this.programs = response.data.results;
      this.currentPage = pageNum;
      this.totalPrograms = response.data.count;
      this.programsCount = get(response.data, 'results.length', 0);
      this.fetchNextClass();
      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + this.programsCount - 1;

      // Adding referred programs after every 5th program
      const programs = cloneDeep(this.programs);
      let count = 1;
      this.programs.forEach((program, index) => {
        if ((index + 1) % 5 === 0 && this.refPrograms.length) {
          programs.splice(index + count, 0, { ...this.refPrograms.shift(), prgType: 'referred' });
          count++;
        }
      });
      this.programs = programs;
      if (this.refPrograms.length && this.pageToCount >= this.totalPrograms) {
        this.refPrograms.forEach(program => {
          this.programs.push({ ...program, prgType: 'referred' });
        });
      }

      this.isLoading = false;
    },

    async fetchReferredPrograms(params = {}) {
      this.areRefLoading = true;

      const response = await this.getSchoolReferredPrograms({
        school: get(this.getCurrentSchool, 'id'),
        ...params,
      });
      this.refPrograms = response.data;

      this.areRefLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPrograms(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPrograms();
    },

    onSearch() {
      this.debouncedSearchPrograms(this);
    },

    debouncedSearchPrograms: debounce(vm => {
      vm.fetchPrograms();
    }, 500),
  },

  async mounted() {},
};
</script>
