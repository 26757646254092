<template>
  <div class="bg-light">
    <div
      :class="containerClass"
      v-if="
        !registeredSuccessfull &&
          !showPaymentDetail &&
          !registeredUnsuccessfull &&
          !showProducts &&
          !showApplication &&
          !showRefundPolicy
      "
      class="offset-lg-2 col-lg-8 col-md-12 bg-white border shadowed"
      style="
        -webkit-box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.75);
      "
    >
      <div class="d-flex justify-content-center mt-2 pt-2">
        <b-skeleton-wrapper :loading="isClassLoading">
          <template #loading>
            <b-skeleton-img width="160px" height="120px" no-aspect></b-skeleton-img>
          </template>
          <b-img :src="get(getCurrentSchool, 'logo_url')" height="110" />
        </b-skeleton-wrapper>
      </div>

      <b-skeleton-wrapper :loading="isClassLoading">
        <template #loading>
          <div class="d-flex justify-content-center mt-3">
            <b-skeleton width="60%"></b-skeleton>
          </div>
        </template>
        <div class="d-flex justify-content-center mt-2 text-center">
          <h3 class="mb-0 font-weight-bolder" v-text="`${get(classSch, 'program.title')} Registration`" />
        </div>
      </b-skeleton-wrapper>

      <div class="mx-3 mb-4 mt-4 text-center">
        <b-skeleton-wrapper :loading="isClassLoading">
          <template #loading>
            <p class="d-flex justify-content-center">
              <b-skeleton width="90%"></b-skeleton>
            </p>
          </template>

          <span style="font-size: 0.9125rem">
            <span v-if="get(getCurrentSchool, 'pp_registration_desc')">
              {{ reg_desc }}
            </span>
            <span v-else-if="get(classSch, 'program.application_fee')">
              <b style="text-transform: uppercase">Note:</b>
              A non-refundable {{ get(getCurrentSchool, 'app_fee_name', '').toLowerCase() || 'application fee' }} of
              <b>{{ $n(get(classSch, 'program.application_fee', 0), 'currency', 'en-US') }}</b> is required for online
              registration submissions. Before registering, please make sure to contact us to make sure all of your
              questions are answered.
            </span>
          </span>
        </b-skeleton-wrapper>
      </div>

      <b-form class="col-md-12 px-0 page-section pt-0 pb-5" @submit.prevent="showApplicationForm">
        <div class="card">
          <div class="card-header bg-light">
            <h5 class="my-0">Class Information</h5>
          </div>
          <div class="card-body">
            <div class="flex">
              <b-skeleton-wrapper :loading="isClassLoading">
                <template #loading>
                  <p class="lead text-white-50">
                    <b-skeleton width="70%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="60%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="50%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="40%"></b-skeleton>
                  </p>
                </template>
                <div class="icon-align mb-2" v-if="classSch.class_type === CLASS_SCHEDULE_TYPES.SCHEDULED">
                  <i class="fas fa-calendar-alt icon-16pt mr-1"></i><b class="mr-1 form-label">Start/End:</b>
                  {{ formatDateSimple(classSch.start_date) }} to
                  {{ formatDateSimple(classSch.end_date) }}
                </div>
                <div class="icon-align mb-2">
                  <i class="fas fa-map-marker-alt icon-16pt mr-1"></i> <b class="mr-1 form-label">Location:</b>
                  <a class="text-underline text-primary">{{ fullClassLocation }}</a>
                </div>
                <div class="icon-align mb-2">
                  <i class="fas fa-dollar-sign icon-16pt mr-1"></i>
                  <b class="mr-1 form-label">{{ get(getCurrentSchool, 'ttl_tuition_name') || 'Total Tuition' }}:</b>
                  {{ $n(totalTuition, 'currency', 'en-US') }}

                  <i
                    class="material-icons icon-16pt clickable-item text-muted"
                    v-b-popover.hover.top="
                      `You don't need to pay this tuition fee right now if you are sponsored by some agency. Contact school admin for more information`
                    "
                    >info</i
                  >
                </div>
                <div class="icon-align mb-2">
                  <i class="fas fa-calendar-week icon-16pt mr-1"></i> <b class="mr-1 form-label">Total Weeks:</b>
                  {{ $n(classSch.total_weeks, 'singleDecimal', 'en-US') }}
                </div>
                <div class="icon-align mb-2">
                  <i class="fas fa-clock icon-16pt mr-1"></i> <b class="mr-1 form-label">Total Hours:</b>
                  {{ $n(classSch.total_hours, 'singleDecimal', 'en-US') }}
                </div>
                <div class="icon-align mb-2" v-if="classSch.orientation_date">
                  <i class="fas fa-calendar-day icon-16pt mr-1"></i> <b class="mr-1 form-label">Orientation Date:</b>
                  {{ formatDateSimple(classSch.orientation_date) }}
                </div>
                <div class="icon-align mb-2">
                  <i class="fas fa-clipboard-list icon-16pt mr-1"></i>
                  <b class="mr-1 form-label">Schedule:</b>
                  {{
                    classSch.class_type === CLASS_SCHEDULE_TYPES.SCHEDULED
                      ? $t(`scheduleTypes.${classSch.schedule_type}`)
                      : 'Self-Paced'
                  }}
                </div>
              </b-skeleton-wrapper>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg-light">
            <h5 class="my-0">Student Information</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <b-form-group label="First Name*" label-for="firstname" label-class="form-label" class="col-md-6">
                <b-form-input
                  id="firstname"
                  :placeholder="$t('userMsgs.firstName')"
                  v-model.trim="user.firstName"
                  :state="!$v.user.firstName.required && $v.user.firstName.$dirty ? false : null"
                />
                <b-form-invalid-feedback v-if="!$v.user.firstName.required"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group label="Last Name*" label-for="lastname" label-class="form-label" class="col-md-6">
                <b-form-input
                  id="lastname"
                  :placeholder="$t('userMsgs.lastName')"
                  v-model.trim="user.lastName"
                  :state="!$v.user.lastName.required && $v.user.lastName.$dirty ? false : null"
                />
                <b-form-invalid-feedback v-if="!$v.user.lastName.required"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>
            </div>

            <b-form-group label="Email Address*" label-for="email" label-class="form-label">
              <b-form-input
                :disabled="$route.query.regId ? true : false"
                id="email"
                :placeholder="$t('authMsgs.emailAddr')"
                type="email"
                v-model.trim="user.email"
                :state="(!$v.user.email.required || !$v.user.email.email) && $v.user.$dirty ? false : null"
              />
              <b-form-invalid-feedback v-if="!$v.user.email.email">
                Invalid email. Please make sure that it's in correct format i.e.
                test@example.com.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Phone*" label-for="phone" label-class="form-label">
              <b-form-input
                id="phone"
                placeholder="(888) 689 - 1235"
                v-model="user.phone"
                v-mask="'(###) ### - ####'"
                :state="
                  (!$v.user.phone.required || !$v.user.phone.isCorrectFormat) && $v.user.phone.$dirty ? false : null
                "
              />
              <b-form-invalid-feedback v-if="!$v.user.phone.required">
                This field is required.
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-else>
                Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Birth Date*" label-for="gradDate" label-class="form-label">
              <date-picker
                v-model="student.birthday"
                type="date"
                format="MM/DD/YYYY"
                value-type="YYYY-MM-DD"
                style="width: 100%"
                id="birthday"
                lang="en"
                placeholder="MM/DD/YYYY"
                :input-class="
                  `mx-input ${
                    !$v.student.birthday.required && $v.student.birthday.$dirty ? 'form-control is-invalid' : ''
                  }`
                "
              ></date-picker>

              <b-form-invalid-feedback v-if="!$v.student.birthday.required && $v.student.birthday.$dirty"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group v-if="responseData.student_ssn !== STATIC_FIELD_TYPE.DISABLED">
              <label for="student_ssn" class="form-label">
                {{ responseData.student_ssn === STATIC_FIELD_TYPE.REQUIRED ? 'SSN*' : 'SSN' }}</label
              >
              <md-icon
                class="mb-1"
                style="font-size: 16px; color: grey"
                v-b-popover.hover.right
                title="Please enter last 4 digits of your SSN."
                >info</md-icon
              >

              <div style="position: relative; display: flex; align-items: center; justify-content: flex-end">
                <b-input-group prepend="***-**-">
                  <b-form-input
                    id="student_ssn"
                    :type="ssnPassType"
                    placeholder="****"
                    v-mask="'####'"
                    v-model="student.student_ssn"
                    :state="!$v.student.student_ssn.required && $v.student.student_ssn.$dirty ? false : null"
                  />
                  <b-form-invalid-feedback v-if="!$v.student.student_ssn.required"
                    >This field is required.</b-form-invalid-feedback
                  >
                </b-input-group>

                <span style="position: absolute; margin-right: 6px" @click="changeField()" class="ml-1 my-auto">
                  <md-icon style="cursor: pointer; font-size: 21px; color: grey">{{
                    visibility ? 'visibility' : 'visibility_off'
                  }}</md-icon>
                </span>
              </div>
            </b-form-group>

            <b-form-group
              :label="
                `Language Preference(s)${responseData.lang_preferences === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''}`
              "
              label-for="lang_preferences"
              label-class="form-label"
              v-if="responseData.lang_preferences !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="lang_preferences"
                class="form-control v-select-custom"
                label="name"
                v-model="student.lang_preferences"
                :reduce="item => item.value"
                placeholder="Select Preferred Language"
                :options="langOptions"
                :class="
                  !$v.student.lang_preferences.required && $v.student.lang_preferences.$dirty
                    ? 'form-control is-invalid'
                    : ''
                "
                multiple
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.student.lang_preferences.required && $v.student.lang_preferences.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="responseData.gender === STATIC_FIELD_TYPE.REQUIRED ? 'Gender*' : 'Gender'"
              label-for="gender"
              label-class="form-label"
              v-if="responseData.gender !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="gender"
                class="form-control v-select-custom"
                label="text"
                v-model="user.gender"
                :reduce="item => item.value"
                placeholder="Select your gender"
                :options="genderOptions"
                :class="!$v.user.gender.required && $v.user.gender.$dirty ? 'form-control is-invalid' : ''"
              >
              </v-select>
              <b-form-invalid-feedback :state="!$v.user.gender.required && $v.user.gender.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="responseData.ethnicity === STATIC_FIELD_TYPE.REQUIRED ? 'Ethnicity*' : 'Ethnicity'"
              label-for="ethnicity"
              label-class="form-label"
              v-if="responseData.ethnicity !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="ethnicity"
                class="form-control v-select-custom"
                label="text"
                v-model="ethnicity"
                :reduce="r => r.value"
                placeholder="Select an Option"
                :options="ethnicityOptions"
                :class="!$v.ethnicity.required && $v.ethnicity.$dirty ? 'form-control is-invalid' : ''"
              >
              </v-select>
              <b-form-invalid-feedback :state="!$v.ethnicity.required && $v.ethnicity.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
              <b-form-input
                id="ethnicity_other"
                class="mt-1"
                placeholder="Please specify"
                v-model="ethnicity_other"
                required
                v-if="ethnicity === 'other'"
                :state="!$v.ethnicity_other.required && $v.ethnicity_other.$dirty ? false : null"
              />

              <b-form-invalid-feedback
                v-if="ethnicity === 'other'"
                :state="
                  (!$v.ethnicity.required && $v.ethnicity.$dirty) ||
                  (!$v.ethnicity_other.required && $v.ethnicity_other.$dirty)
                    ? false
                    : null
                "
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <!-- Address Fields -->
            <b-form-group
              :label="responseData.address === STATIC_FIELD_TYPE.REQUIRED ? 'Address*' : 'Address'"
              label-for="address"
              label-class="form-label"
              v-if="responseData.address !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="address"
                :placeholder="$t('addressMsgs.address')"
                v-model="student.address"
                :state="!$v.student.address.required && $v.student.address.$dirty ? false : null"
              />
              <b-form-invalid-feedback :state="!$v.student.address.required && $v.student.address.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <div class="row">
              <b-form-group
                :label="responseData.city === STATIC_FIELD_TYPE.REQUIRED ? 'City*' : 'City'"
                label-for="city"
                label-class="form-label"
                class="col-md-6"
                v-if="responseData.city !== STATIC_FIELD_TYPE.DISABLED"
              >
                <b-form-input
                  id="city"
                  :placeholder="$t('addressMsgs.city')"
                  v-model="student.city"
                  :state="!$v.student.city.required && $v.student.city.$dirty ? false : null"
                />
                <b-form-invalid-feedback :state="!$v.student.city.required && $v.student.city.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group
                :label="responseData.state === STATIC_FIELD_TYPE.REQUIRED ? 'State*' : 'State'"
                label-for="state"
                label-class="form-label"
                class="col-md-6"
                v-if="responseData.state !== STATIC_FIELD_TYPE.DISABLED"
              >
                <v-select
                  id="state"
                  class="form-control v-select-custom"
                  label="text"
                  v-model="student.state"
                  :reduce="state => state.value"
                  placeholder="Select State"
                  :options="stateOptions"
                  :class="!$v.student.state.required && $v.student.state.$dirty ? 'form-control is-invalid' : ''"
                >
                </v-select>
                <b-form-invalid-feedback :state="!$v.student.state.required && $v.student.state.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>
            </div>

            <div class="row">
              <b-form-group
                :label="responseData.zipcode === STATIC_FIELD_TYPE.REQUIRED ? 'Zipcode*' : 'Zipcode'"
                label-for="zipcode"
                label-class="form-label"
                class="col-md-6"
                v-if="responseData.zipcode !== STATIC_FIELD_TYPE.DISABLED"
              >
                <b-form-input
                  id="zipcode"
                  :placeholder="$t('addressMsgs.zipcode')"
                  v-model="student.zipcode"
                  :state="!$v.student.zipcode.required && $v.student.zipcode.$dirty ? false : null"
                />
                <b-form-invalid-feedback
                  :state="!$v.student.zipcode.required && $v.student.zipcode.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                :label="responseData.country === STATIC_FIELD_TYPE.REQUIRED ? 'Country*' : 'Country'"
                label-for="country"
                label-class="form-label"
                class="col-md-6"
                v-if="responseData.country !== STATIC_FIELD_TYPE.DISABLED"
              >
                <v-select
                  id="country"
                  class="form-control v-select-custom"
                  label="text"
                  v-model="student.country"
                  disabled
                  :reduce="country => country.value"
                  :placeholder="$t('addressMsgs.countryPlaceholder')"
                  :options="countryOptions"
                  :class="!$v.student.country.required && $v.student.country.$dirty ? 'form-control is-invalid' : ''"
                >
                </v-select>
                <b-form-invalid-feedback
                  :state="!$v.student.country.required && $v.student.country.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>
            </div>

            <b-form-group
              :label="
                `Why are you interested in registering for the ${get(classSch, 'program.title', '')} program?${
                  responseData.registration_reason === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
                }`
              "
              label-for="registration_reason"
              label-class="form-label"
              v-if="responseData.registration_reason !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="registration_reason"
                class="form-control v-select-custom"
                label="text"
                v-model="registration_reason"
                :reduce="r => r.value"
                placeholder="Select the reason for registration"
                :options="allReasons"
                :class="
                  !$v.registration_reason.required && $v.registration_reason.$dirty ? 'form-control is-invalid' : ''
                "
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.registration_reason.required && $v.registration_reason.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
              <b-form-input
                id="other_registration_reason"
                class="mt-1"
                placeholder="Please specify"
                v-model="other_registration_reason"
                required
                v-if="registration_reason === 'other'"
                :state="!$v.other_registration_reason.required && $v.other_registration_reason.$dirty ? false : null"
              />

              <b-form-invalid-feedback
                v-if="registration_reason === 'other'"
                :state="
                  (!$v.registration_reason.required && $v.registration_reason.$dirty) ||
                  (!$v.other_registration_reason.required && $v.other_registration_reason.$dirty)
                    ? false
                    : null
                "
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="
                `How did you hear about our ${get(classSch, 'program.title', '')} program?${
                  responseData.reference === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
                }`
              "
              label-for="reference"
              label-class="form-label"
              v-if="responseData.reference !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="reference"
                class="form-control v-select-custom"
                label="text"
                v-model="reference"
                :reduce="r => r.value"
                placeholder="Select reference"
                :options="allReferals"
                :class="!$v.reference.required && $v.reference.$dirty ? 'form-control is-invalid' : ''"
              >
              </v-select>
              <b-form-invalid-feedback :state="!$v.reference.required && $v.reference.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
              <b-form-input
                id="other_reference"
                class="mt-1"
                placeholder="Please specify"
                v-model="other_reference"
                required
                v-if="reference === 'other'"
                :state="!$v.other_reference.required && $v.other_reference.$dirty ? false : null"
              />

              <b-form-invalid-feedback
                v-if="reference === 'other'"
                :state="
                  (!$v.reference.required && $v.reference.$dirty) ||
                  (!$v.other_reference.required && $v.other_reference.$dirty)
                    ? false
                    : null
                "
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>
          </div>
        </div>

        <!-- Student Profile Info -->

        <div class="card">
          <div class="card-header bg-light">
            <h5 class="my-0">Career Information</h5>
          </div>
          <div class="card-body">
            <b-form-group
              label="Do you have a High School Diploma/ GED?*"
              label-for="ged_or_diploma"
              label-class="form-label"
            >
              <b-form-radio-group
                id="ged_or_diploma"
                v-model="student.ged_or_diploma"
                :options="yesNoOptions"
                required
                :state="!$v.student.ged_or_diploma.required && $v.student.ged_or_diploma.$dirty ? false : null"
              ></b-form-radio-group>
              <b-form-invalid-feedback
                :state="!$v.student.ged_or_diploma.required && $v.student.ged_or_diploma.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group :label="`Graduation Year`" label-for="gradDate" label-class="form-label">
              <date-picker
                v-model="student.diploma_date"
                type="year"
                format="YYYY"
                style="width: 100%"
                value-type="date"
                id="diploma_date"
                lang="en"
                placeholder="No Date Selected"
              ></date-picker>
            </b-form-group>

            <b-form-group
              label="Have you been certified before?*"
              label-for="certified_before"
              label-class="form-label"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                id="certified_before"
                v-model="student.certified_before"
                :options="yesNoOptions"
                :aria-describedby="ariaDescribedby"
                required
                :state="!$v.student.certified_before.required && $v.student.certified_before.$dirty ? false : null"
              ></b-form-radio-group>
              <b-form-invalid-feedback
                :state="!$v.student.certified_before.required && $v.student.certified_before.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="
                `Where do you currently work?${responseData.occupation === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''}`
              "
              label-for="occupation"
              label-class="form-label"
              v-if="responseData.occupation !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="occupation"
                placeholder="Please enter where your currently work?"
                v-model="student.occupation"
                :state="!$v.student.occupation.required && $v.student.occupation.$dirty ? false : null"
              />

              <b-form-invalid-feedback
                :state="!$v.student.occupation.required && $v.student.occupation.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              label="Are you interested in receiving new opportunities from employers we partner with?*"
              label-for="career_service_interest"
              label-class="form-label"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                id="career_service_interest"
                v-model="student.career_service_interest"
                :options="yesNoOptions"
                :aria-describedby="ariaDescribedby"
                required
                :state="
                  !$v.student.career_service_interest.required && $v.student.career_service_interest.$dirty
                    ? false
                    : null
                "
              ></b-form-radio-group>
              <b-form-invalid-feedback
                :state="
                  !$v.student.career_service_interest.required && $v.student.career_service_interest.$dirty
                    ? false
                    : null
                "
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <!-- Work Schedule Fields -->
            <b-form-group
              :label="
                `What type(s) of facilities do you prefer to work at?${
                  responseData.interested_facility_types === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
                }`
              "
              label-for="interested_facility_types"
              label-class="form-label"
              v-if="responseData.interested_facility_types !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="interested_facility_types"
                class="form-control v-select-custom"
                label="text"
                v-model="interested_facility_types"
                :reduce="item => item.value"
                placeholder="Select preferred facility type(s)"
                :options="facilityTypeOptions"
                multiple
                :class="
                  !$v.interested_facility_types.required && $v.interested_facility_types.$dirty
                    ? 'form-control is-invalid'
                    : ''
                "
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.interested_facility_types.required && $v.interested_facility_types.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              :label="
                `What type(s) of schedule do you prefer to work?${
                  responseData.desired_work_type === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
                }`
              "
              label-for="desired_work_type"
              label-class="form-label"
              v-if="responseData.desired_work_type !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="desired_work_type"
                class="form-control v-select-custom"
                label="text"
                v-model="student.desired_work_type"
                :reduce="item => item.value"
                placeholder="Select preferred work schedule(s)"
                :options="workTypeOptions"
                multiple
                :class="
                  !$v.student.desired_work_type.required && $v.student.desired_work_type.$dirty
                    ? 'form-control is-invalid'
                    : ''
                "
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.student.desired_work_type.required && $v.student.desired_work_type.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="
                `What time(s) do you prefer to work?${
                  responseData.desired_work_time === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
                }`
              "
              label-for="desired_work_time"
              label-class="form-label"
              v-if="responseData.desired_work_time !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="desired_work_time"
                class="form-control v-select-custom"
                label="text"
                v-model="student.desired_work_time"
                :reduce="item => item.value"
                placeholder="Select preferred work time(s)"
                :options="workTimeOptions"
                multiple
                :class="
                  !$v.student.desired_work_time.required && $v.student.desired_work_time.$dirty
                    ? 'form-control is-invalid'
                    : ''
                "
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.student.desired_work_time.required && $v.student.desired_work_time.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="
                `What day(s) do you prefer to work?${
                  responseData.desired_work_day === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
                }`
              "
              label-for="desired_work_day"
              label-class="form-label"
              v-if="responseData.desired_work_day !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="desired_work_day"
                class="form-control v-select-custom"
                label="text"
                v-model="student.desired_work_day"
                :reduce="item => item.value"
                placeholder="Select preferred work day(s)"
                :options="workDayOptions"
                multiple
                :class="
                  !$v.student.desired_work_day.required && $v.student.desired_work_day.$dirty
                    ? 'form-control is-invalid'
                    : ''
                "
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.student.desired_work_day.required && $v.student.desired_work_day.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              :label="
                `What distance are you willing to travel for work?${
                  responseData.work_travel === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''
                }`
              "
              label-for="work_travel"
              label-class="form-label"
              v-if="responseData.work_travel !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="work_travel"
                class="form-control v-select-custom"
                label="text"
                v-model="student.work_travel"
                :reduce="item => item.value"
                placeholder="Select preferred mile(s)"
                :options="workTravelOptions"
                :class="
                  !$v.student.work_travel.required && $v.student.work_travel.$dirty ? 'form-control is-invalid' : ''
                "
              >
              </v-select>
              <b-form-invalid-feedback
                :state="!$v.student.work_travel.required && $v.student.work_travel.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              label="Resume (Optional)"
              label-for="resume"
              label-class="form-label"
              class="row-align-items-center"
              v-if="responseData.resume_url !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-media class="align-items-center" vertical-align="center">
                <b-form-file
                  id="resume"
                  placeholder="Select Resume File"
                  :browse-text="$t('generalMsgs.browse')"
                  v-model="selectedUploadFile.resume"
                  @input="uploadFile('resume', 'documents', 'resume_url')"
                  :disabled="isUploading.resume"
                  accept=".pdf, .docx, .doc"
                  :state="!$v.student.resume_url.required && $v.student.resume_url.$dirty ? false : null"
                />
              </b-media>
              <b-form-invalid-feedback
                :state="!$v.student.resume_url.required && $v.student.resume_url.$dirty ? false : null"
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              label="Career Profile Image (Optional)"
              label-for="avatar"
              label-class="form-label"
              class="row-align-items-center"
            >
              <b-media class="align-items-center" vertical-align="center">
                <user-avatar
                  slot="aside"
                  rounded="lg"
                  size="lg"
                  :isLoading="isUploading.avatar"
                  :user="user"
                  url-field="avatarUrl"
                  first-name-field="firstName"
                  last-name-field="lastName"
                >
                </user-avatar>

                <image-uploader
                  @image="e => setImage(e, 'avatar', 'images')"
                  :isUploading="isUploading.avatar"
                  :isRequired="false"
                  placeholder="Select image for career profile"
                />
              </b-media>
            </b-form-group>
          </div>
        </div>

        <div class="card" v-for="section in sections" :key="`sec-${section.id}`">
          <div class="card-header bg-light">
            <h5 class="my-0">{{ section.title }}</h5>
          </div>
          <div class="card-body">
            <b-form-group
              :label="ques.is_required ? `${get(ques, 'question.title')}*` : get(ques, 'question.title')"
              :label-for="`section-${section.id}-question-${ques.question.id}`"
              label-class="form-label"
              v-slot="{ ariaDescribedby }"
              v-for="ques in section.sectionquestion_set"
              :key="`sec-que-${ques.id}`"
            >
              <b-form-radio-group
                v-if="ques.question.type === QUES_TYPES.BOOLEAN"
                :id="`section-${section.id}-question-${ques.question.id}`"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}`]"
                :options="get(ques.question, 'extra.options', [])"
                :aria-describedby="ariaDescribedby"
                :state="
                  !get($v, `customSectionData.section-${section.id}.question-${ques.question.id}.required`, true) &&
                  get($v, `customSectionData.section-${section.id}.question-${ques.question.id}.$dirty`, false)
                    ? false
                    : null
                "
                :required="ques.is_required"
              ></b-form-radio-group>

              <b-form-input
                :id="`section-${section.id}-question-${ques.question.id}`"
                v-if="ques.question.type === QUES_TYPES.SHORT_TEXT"
                :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}`]"
                :state="
                  !get($v, `customSectionData.section-${section.id}.question-${ques.question.id}.required`, true) &&
                  get($v, `customSectionData.section-${section.id}.question-${ques.question.id}.$dirty`, false)
                    ? false
                    : null
                "
                :required="ques.is_required"
              />
              <b-form-textarea
                v-if="ques.question.type === QUES_TYPES.LONG_TEXT"
                :id="`section-${section.id}-question-${ques.question.id}`"
                class="form-control"
                rows="10"
                :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}`]"
                :state="
                  !get($v, `customSectionData.section-${section.id}.question-${ques.question.id}.required`, true) &&
                  get($v, `customSectionData.section-${section.id}.question-${ques.question.id}.$dirty`, false)
                    ? false
                    : null
                "
                :required="ques.is_required"
              ></b-form-textarea>

              <b-media
                class="align-items-center"
                vertical-align="center"
                v-if="ques.question.type === QUES_TYPES.FILE_TYPE"
              >
                <b-form-file
                  :id="`section-${section.id}-question-${ques.question.id}`"
                  :placeholder="get(ques.question, 'placeholder', 'Select File')"
                  :browse-text="$t('generalMsgs.browse')"
                  v-model="selectedQueUploadFile[`sec-${section.id}-que-${ques.question.id}`]"
                  @input="uploadFile('file_type', 'documents', '', section.id, ques.question.id)"
                  :disabled="isQueFileUploading[`sec-${section.id}-que-${ques.question.id}`]"
                  :required="ques.is_required && !selectedQueUploadFile[`sec-${section.id}-que-${ques.question.id}`]"
                />
              </b-media>

              <v-select
                v-if="ques.question.type === QUES_TYPES.SINGLE_SELECT"
                :id="`section-${section.id}-question-${ques.question.id}`"
                class="form-control v-select-custom"
                label="text"
                item-text="text"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}`]"
                :reduce="ins => ins.value"
                :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
                :options="get(ques.question, 'extra.options', [])"
                :class="
                  !get($v, `customSectionData.section-${section.id}.question-${ques.question.id}.required`, true) &&
                  get($v, `customSectionData.section-${section.id}.question-${ques.question.id}.$dirty`, false)
                    ? 'form-control is-invalid'
                    : ''
                "
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="
                      ques.is_required && !customSectionData[`section-${section.id}`][`question-${ques.question.id}`]
                    "
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template slot="option" slot-scope="option"> {{ option.text }} </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.text }}
                </template>
              </v-select>
              <v-select
                v-if="ques.question.type === QUES_TYPES.MULTI_SELECT"
                :id="`section-${section.id}question-${ques.question.id}`"
                class="form-control v-select-custom"
                label="text"
                item-text="text"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}`]"
                :reduce="ins => ins.value"
                :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
                :options="get(ques.question, 'extra.options', [])"
                :class="
                  !get($v, `customSectionData.section-${section.id}.question-${ques.question.id}.required`, true) &&
                  get($v, `customSectionData.section-${section.id}.question-${ques.question.id}.$dirty`, false)
                    ? 'form-control is-invalid'
                    : ''
                "
                multiple
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="
                      ques.is_required &&
                        !get(customSectionData[`section-${section.id}`][`question-${ques.question.id}`], 'length')
                    "
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template slot="option" slot-scope="option"> {{ option.text }} </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.text }}
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="
                  !get($v, `customSectionData.section-${section.id}.question-${ques.question.id}.required`, true) &&
                  get($v, `customSectionData.section-${section.id}.question-${ques.question.id}.$dirty`, false)
                    ? false
                    : null
                "
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>
          </div>
        </div>

        <div class="card">
          <div class="card-header bg-light">
            <h5 class="my-0">{{ get(getCurrentSchool, 'tuition_fee_name') || 'Tuition' }} and Payment Plan</h5>
          </div>
          <div class="card-body">
            <b-skeleton-wrapper :loading="isClassLoading">
              <template #loading>
                <p class="lead text-white-50">
                  <b-skeleton width="70%"></b-skeleton>
                </p>
                <p class="lead text-white-50">
                  <b-skeleton width="60%"></b-skeleton>
                </p>
                <p class="lead text-white-50">
                  <b-skeleton width="50%"></b-skeleton>
                </p>
              </template>

              <b-form-group
                :label="
                  `When do you plan to pay the remaining ${get(getCurrentSchool, 'tuition_fee_name') ||
                    'tuition fee'} (${$n(get(classSch, 'program.tuition_fee', 0), 'currency', 'en-US')})?*`
                "
                label-for="payment_option"
                label-class="form-label"
                v-if="get(classSch, 'program.tuition_reg_req') === false"
                ><span v-if="$route.query.regId">
                  {{ $t(`stdRegPaymentOption.${payment_option}`) }}
                </span>
                <v-select
                  v-else
                  id="payment_option"
                  class="form-control v-select-custom"
                  label="text"
                  v-model="payment_option"
                  :reduce="item => item.value"
                  placeholder="Select how would you like to pay the fees"
                  :options="STD_REG_FORM_PAY_OPTIONS"
                  :class="!$v.payment_option.required && $v.payment_option.$dirty ? 'form-control is-invalid' : ''"
                  :clearable="false"
                  :disabled="get(classSch, 'program.allow_pymnt_option') === PROGRAM_PAYMENT_OPTIONS.PAY_LATER"
                >
                </v-select>
                <b-form-invalid-feedback :state="!$v.payment_option.required && $v.payment_option.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                :label="
                  `How will you be covering the remainder of your ${get(getCurrentSchool, 'tuition_fee_name') ||
                    'tuition fee'}?${responseData.plan_to_pay === STATIC_FIELD_TYPE.REQUIRED ? '*' : ''}`
                "
                label-for="plan_to_pay"
                label-class="form-label"
                v-if="responseData.plan_to_pay !== STATIC_FIELD_TYPE.DISABLED"
              >
                <!-- <v-select
                  id="plan_to_pay"
                  class="form-control v-select-custom"
                  label="text"
                  v-model="plan_to_pay"
                  :reduce="(r) => r.value"
                  placeholder="Select how you are planning to pay"
                  :options="planToPayOptions"
                  :class="!$v.plan_to_pay.required && $v.plan_to_pay.$dirty ? 'form-control is-invalid' : ''"
                >
                </v-select> -->
                <div
                  @click.prevent="
                    () => {
                      plan_to_pay = option.value;
                    }
                  "
                  style="cursor: pointer"
                  class="card p-3 mb-2"
                  v-for="option in stdPlanToPayOptions(get(this.getCurrentSchool, 'tuition_fee_name'))"
                  :key="option.value"
                >
                  <b-form-radio
                    size="lg"
                    name="plan-to-pay-radio"
                    :value="option.value"
                    v-model="plan_to_pay"
                    :class="!$v.plan_to_pay.required && $v.plan_to_pay.$dirty ? 'form-control is-invalid' : ''"
                  >
                    <i style="font-size: 23px" :class="`fas ${option.icon} text-primary mr-1 mb-1`" />
                    <span style="font-size: 16px">
                      {{ option.text }}
                    </span>
                  </b-form-radio>
                  <span :class="option.description ? 'mt-2' : ''">
                    {{ option.description }}
                  </span>
                  <div
                    v-if="plan_to_pay === 'sponsored_employer_agency' && option.value === 'sponsored_employer_agency'"
                  >
                    <v-select
                      id="sponsorship_select"
                      class="form-control v-select-custom my-2"
                      label="title"
                      v-model="selectedSponsorship"
                      :reduce="sponsorship => sponsorship"
                      placeholder="Select sponsorship"
                      :options="sponsorshipOptions"
                      :clearable="false"
                      :class="
                        !$v.selectedSponsorship.required && $v.selectedSponsorship.$dirty
                          ? 'form-control is-invalid'
                          : ''
                      "
                    >
                    </v-select>
                    <b-form-invalid-feedback
                      :state="!$v.selectedSponsorship.required && $v.selectedSponsorship.$dirty ? false : null"
                      >This field is required.</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <b-form-invalid-feedback :state="!$v.plan_to_pay.required && $v.plan_to_pay.$dirty ? false : null"
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>
            </b-skeleton-wrapper>
          </div>
        </div>

        <!-- Terms & Conditions Card -->
        <div class="card">
          <div class="card-header bg-light">
            <h5 class="my-0">Terms and Conditions</h5>
          </div>
          <div class="card-body">
            <b-form-group label-class="form-label">
              <b-form-checkbox v-model="termsAndConditions" name="check-button">
                <span style="font-weight: initial" class="text-black">
                  I understand and accept the
                  <a href="#" @click.prevent="showTermsModal = true" style="color: #00b3e6">terms and conditions</a>.
                </span>
              </b-form-checkbox>
            </b-form-group>

            <b-form-group label="Signature*" label-for="signature" label-class="form-label">
              <b-form-input
                id="signature"
                placeholder="Type Full Name"
                v-model="signature"
                :state="!$v.signature.required && $v.signature.$dirty ? false : null"
                maxlength="60"
              />

              <b-form-invalid-feedback v-if="!$v.signature.required">This field is required.</b-form-invalid-feedback>
            </b-form-group>

            <div class="text-dark" v-if="signature">Preview</div>
            <h1 class="signature-font">{{ signature }}</h1>
          </div>
        </div>

        <b-btn
          variant="primary"
          :disabled="isFormLoading || !termsAndConditions"
          style="width: 150px"
          type="submit"
          class="btn-normal float-right"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Continue</span>
        </b-btn>
      </b-form>
    </div>

    <!-- Product content -->
    <div
      :class="containerClass"
      v-else-if="showProducts && !showPaymentDetail && !showRefundPolicy"
      class="offset-lg-2 col-lg-8 col-md-12 bg-white border"
    >
      <product-card
        :class-sch="classSch"
        :products="products"
        :are-products-loading="areProductsLoading"
        :cart-arr="cartArr"
        :is-payment-details-loading="isPaymentDetailsLoading"
        :is-form-loading="isFormLoading"
        :is-pay-loading="isPayLoading"
        :is-loading="isLoading"
        @increase="incrementQuantity"
        @decrease="decrementQuantity"
        @updateCart="addToCart"
        @remove="removeFromCart"
        @back="backFromProductInfo"
        @continue="onSubmit"
      />
    </div>

    <div
      :class="containerClass"
      v-else-if="!showPaymentDetail && showRefundPolicy"
      class="offset-lg-2 col-lg-8 col-md-12 bg-white border"
    >
      <div class="d-flex justify-content-center mt-2 pt-2">
        <b-img :src="get(getCurrentSchool, 'logo_url')" height="110" />
      </div>
      <div class="d-flex justify-content-center mt-2 text-center">
        <h3 class="mb-0 font-weight-bolder" v-text="`${get(classSch, 'program.title')} Registration`" />
      </div>

      <div class="py-5" style="padding-inline: 100px;">
        <div
          class="my-3"
          v-safe-html="get(getCurrentSchool, 'cancellation_terms')"
          v-if="get(getCurrentSchool, 'cancellation_terms')"
        />

        <hr />

        <b-form-group>
          <b-form-checkbox id="checkbox" v-model="acceptRefundPolicy" name="checkbox">
            I,
            <b>
              {{ user.firstName }}
              {{ user.lastName }}
            </b>
            , have read the above stated terms and conditions. I hereby agree to the cancellation/refund policy of
            <b>
              {{ get(getCurrentSchool, 'name') }}
            </b>
            .
          </b-form-checkbox>
        </b-form-group>
        <span class="mb-2"> Date: {{ formatFullDate(new Date()) }} </span>

        <b-btn
          variant="primary"
          :disabled="isFormLoading || !acceptRefundPolicy"
          style="width: 150px"
          @click.prevent="acceptRefund"
          class="btn-normal float-right my-3"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Continue</span>
        </b-btn>
      </div>
    </div>

    <!-- Checkout content. -->

    <div :class="containerClass" v-else-if="showPaymentDetail" class="offset-lg-2 col-lg-8 col-md-12 bg-white border">
      <div class="d-flex justify-content-center mt-2 pt-2">
        <b-img :src="get(getCurrentSchool, 'logo_url')" height="110" />
      </div>
      <div class="d-flex justify-content-center mt-2 text-center">
        <h3 class="mb-0 font-weight-bolder" v-text="`${get(classSch, 'program.title')} Registration`" />
      </div>
      <div class="mx-3 mt-3 mb-4 text-center">
        <span style="font-size: 0.9125rem">
          Confirm the details below and add your payment information to complete the registration.
        </span>
      </div>
      <b-form class="col-md-12 px-0 page-section pt-0 pb-5 mb-5" @submit.prevent="pay">
        <div class="row px-0">
          <div class="col-md-7 d-flex align-items-stretch">
            <div class="card w-100">
              <div class="card-header bg-light">
                <h5 class="my-0">Student Summary</h5>
              </div>
              <div class="card-body">
                <div class="icon-align mb-2">
                  <i class="fas fa-user-alt icon-16pt mr-1"></i>{{ user.firstName }} {{ user.lastName }}
                </div>
                <div class="icon-align mb-2"><i class="fas fa-envelope icon-16pt mr-1"></i>{{ user.email }}</div>
                <div class="icon-align mb-2"><i class="fas fa-phone icon-16pt mr-1"></i>{{ user.phone }}</div>

                <div class="icon-align mb-2" v-if="enteredStdAddr">
                  <i class="fas fa-map-marker-alt icon-16pt mr-1"></i>{{ enteredStdAddr }}
                </div>
                <div class="icon-align mb-2">
                  <i class="fas fa-calendar-alt icon-16pt mr-1"></i>Classes starting from
                  {{ formatFullDate(classSch.start_date) }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 d-flex align-items-stretch">
            <div class="card w-100">
              <div class="card-header bg-light">
                <h5 class="my-0">Amount Due</h5>
              </div>
              <div class="card-body d-flex align-items-center">
                <div class="w-100">
                  <div
                    class="icon-align mb-2 d-flex justify-content-between"
                    v-if="payment_option === STD_REG_PAY_OPTIONS.PAY_NOW"
                  >
                    <b class="mr-1"> {{ get(getCurrentSchool, 'tuition_fee_name') || 'Tuition Fee' }}:</b>
                    {{
                      payment_option === STD_REG_PAY_OPTIONS.FREE_PLAN && !cartArr.length
                        ? $n(0, 'currency', 'en-US')
                        : $n(get(classSch, 'program.tuition_fee', 0), 'currency', 'en-US')
                    }}
                  </div>

                  <div class="icon-align mb-2 d-flex justify-content-between">
                    <b class="mr-1"> {{ get(getCurrentSchool, 'app_fee_name') || 'Application Fee' }}:</b>
                    {{ $n(calcPrgApplicationFee, 'currency', 'en-US') }}
                  </div>

                  <div class="icon-align mb-2 d-flex justify-content-between" v-if="get(cartArr, 'length')">
                    <b class="mr-1">Products:</b>
                    {{
                      payment_option === STD_REG_PAY_OPTIONS.FREE_PLAN && !cartArr.length
                        ? $n(0, 'currency', 'en-US')
                        : $n(totalAmount, 'currency', 'en-US')
                    }}
                  </div>

                  <div class="icon-align mb-2 d-flex justify-content-between">
                    <b class="mr-1">Processing Fee:</b>
                    {{
                      (payment_option === STD_REG_PAY_OPTIONS.FREE_PLAN && !cartArr.length) ||
                      (payment_option === STD_REG_PAY_OPTIONS.PAY_LATER && !cartArr.length && waiveAppFee)
                        ? $n(0, 'currency', 'en-US')
                        : $n(calcServiceFee, 'currency', 'en-US')
                    }}
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <b>Total Amount Due:</b>
                    <b>{{
                      (payment_option === STD_REG_PAY_OPTIONS.FREE_PLAN && !cartArr.length) ||
                      (payment_option === STD_REG_PAY_OPTIONS.PAY_LATER && !cartArr.length && waiveAppFee)
                        ? $n(0, 'currency', 'en-US')
                        : $n(calTotalFee, 'currency', 'en-US')
                    }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="get(cartArr, 'length')">
          <product-checkout-card :cart-arr="cartArr" />
        </div>

        <div
          class="card"
          v-if="
            [STD_REG_PAY_OPTIONS.PAY_NOW].includes(payment_option) ||
              (payment_option === STD_REG_PAY_OPTIONS.FREE_PLAN && cartArr.length) ||
              (payment_option !== STD_REG_PAY_OPTIONS.PAY_LATER && cartArr.length) ||
              (payment_option === STD_REG_PAY_OPTIONS.PAY_LATER && cartArr.length && waiveAppFee) ||
              (payment_option !== STD_REG_PAY_OPTIONS.PAY_LATER &&
                cartArr.length &&
                get(classSch, 'program.application_fee')) ||
              (payment_option === STD_REG_PAY_OPTIONS.PAY_LATER && !waiveAppFee)
          "
        >
          <div class="card-header bg-light">
            <h5 class="my-0">Payment Information</h5>
          </div>

          <div class="card-body">
            <b-skeleton-wrapper :loading="isPaymentDetailsLoading">
              <template #loading>
                <p class="lead text-white-50">
                  <b-skeleton width="70%"></b-skeleton>
                </p>
                <p class="lead text-white-50">
                  <b-skeleton width="60%"></b-skeleton>
                </p>
                <p class="lead text-white-50">
                  <b-skeleton width="50%"></b-skeleton>
                </p>
              </template>
            </b-skeleton-wrapper>
            <stripe-element-payment
              ref="paymentRef"
              :pk="pk"
              :testMode="testMode"
              :elements-options="elementsOptions"
              :confirm-params="confirmParams"
              @loading="value => (isPayLoading = value)"
              @element-ready="() => (isPaymentDetailsLoading = false)"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <b-btn
            variant="light"
            :disabled="isPayLoading"
            style="width: 150px"
            @click.prevent="backToForm"
            class="btn-normal float-right"
          >
            <span>Back</span>
          </b-btn>

          <b-btn
            variant="primary"
            :disabled="
              [STD_REG_PAY_OPTIONS.PAY_NOW, STD_REG_PAY_OPTIONS.PAY_LATER].includes(payment_option) && cartArr.length
                ? isPayLoading || isFormLoading || isPaymentDetailsLoading
                : false
            "
            style="width: 150px"
            type="submit"
            class="btn-normal float-right"
          >
            <i v-if="isPayLoading" class="fas fa-circle-notch fa-spin"></i>
            <span v-else>Register</span>
          </b-btn>
        </div>
      </b-form>
    </div>

    <!-- Registeration success component -->
    <div class="page-section" v-else-if="registeredSuccessfull && !showPaymentDetail && !showProducts">
      <div :class="containerClass" class="mt-5">
        <div class="page-section d-flex justify-content-center">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-check-circle fa-lg text-success mt-4 mb-4" style="font-size: 3rem" />
              <h4>Successfully Registered</h4>

              <p class="text-muted">
                {{
                  `You are successfully registered as a student in ${get(
                    classSch,
                    'program.title',
                    'class'
                  )} and will receive an e-mail shortly. For any
                queries, please contact the administrator.`
                }}
              </p>
              <a href="#" @click.prevent="goToHome" class="btn btn-primary"
                ><i class="fas fa-home mr-2"></i>Back to Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Registeration failure component -->
    <div class="page-section" v-else-if="registeredUnsuccessfull && !showPaymentDetail && !showProducts">
      <div :class="containerClass" class="mt-5">
        <div class="page-section d-flex justify-content-center">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-times-circle fa-lg text-danger mt-4 mb-4" style="font-size: 3rem" />
              <h4>Registeration Failed!</h4>

              <p class="text-muted">
                Cannot register you in this class. Please try again later or contact the administrator.
              </p>
              <a href="#" @click.prevent="goToHome" class="btn btn-primary"
                ><i class="fas fa-home mr-2"></i>Back to Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="showApplication">
      <application-form :scholarship="selectedSponsorship" @ApplicationForm="ApplicationFormResponse" />
    </div>

    <reg-terms-modal :show-modal="showTermsModal" @close="showTermsModal = false"></reg-terms-modal>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { get, map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { countries } from 'countries-list';
import generator from 'generate-password';
import Page from '@/components/Page.vue';
import UserAvatar from '../../../../components/User/UserAvatar.vue';
import DatePicker from 'vue2-datepicker';
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import ProductCard from './ProductCard.vue';
import ProductCheckoutCard from './ProductCheckoutCard.vue';
import ApplicationForm from './ApplicationForm.vue';
import {
  PHONE_FORMAT_REGEX,
  ETHNICITY_OPTIONS,
  STATIC_FIELD_TYPE,
  QUES_TYPES,
  STD_REG_REASON_OPTIONS,
  YES_NO_OPTIONS,
  GENDER_OPTIONS,
  STD_REG_REFERRAL_OPTIONS,
  STD_REG_FORM_PAY_OPTIONS,
  STD_REG_PAY_OPTIONS,
  STD_REG_MIN_SERVICE_FEE,
  PROGRAM_PAYMENT_OPTIONS,
  STD_PLAN_TO_PAY,
  SPONSORSHIP_SUBMISSION_TYPES,
  CLASS_SCHEDULE_TYPES,
} from '../../../../common/constants';
import {
  getLangOptions,
  formatDateSimple,
  formatToAPIDate,
  getWorkTravelOptions,
  formatFullDate,
  stdPlanToPayOptions,
  titleize,
} from '../../../../common/utils';
import RegTermsModal from './RegTermsModal.vue';
import { captureException } from '@sentry/vue';
var UsaStates = require('usa-states').UsaStates;
import vSelect from 'vue-select';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    UserAvatar,
    DatePicker,
    RegTermsModal,
    StripeElementPayment,
    ProductCard,
    ProductCheckoutCard,
    ApplicationForm,
    vSelect,
    ImageUploader,
  },
  extends: Page,
  layout: 'blank',

  data() {
    return {
      ssnPassType: 'password',
      visibility: false,
      title: 'Registration',
      registeredSuccessfull: false,
      registeredUnsuccessfull: false,
      pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      testMode: process.env.VUE_APP_TEST_MODE === 'true',
      isPayLoading: false,
      isPaymentDetailsLoading: false,
      areProductsLoading: false,
      products: [],
      CLASS_SCHEDULE_TYPES,
      cartArr: [],
      elementsOptions: {
        appearance: { theme: 'stripe' }, // appearance options
        // 'clientSecret' being added below.
      },
      intentDraftId: null,
      confirmParams: {},
      isLoading: false,
      termsAndConditions: false,
      showTermsModal: false,
      showPaymentDetail: false,
      showRefundPolicy: false,
      acceptRefundPolicy: false,
      showProducts: false,
      school: null,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        phone: '',
        bio: '',
        avatarUrl: '',
        gender: '',
      },
      student: {
        birthday: null,
        is_profile_visible: true,

        desired_work_type: [],
        desired_work_time: [],
        desired_work_day: [],
        work_travel: '',
        lang_preferences: ['en'],
        certified_before: false,
        ged_or_diploma: null,
        diploma_date: '',
        resume_url: '',
        address: '',
        zipcode: '',
        city: '',
        state: '',
        country: 'United States',

        career_service_interest: true,
        student_ssn: '',

        occupation: '',
      },
      plan_to_pay: STD_PLAN_TO_PAY.SELF_PAY,
      ethnicity: '',
      ethnicity_other: '',
      registration_reason: '',
      other_registration_reason: '',
      other_reference: '',
      reference: '',
      signature: '',
      interested_facility_types: [],

      payment_option: STD_REG_PAY_OPTIONS.PAY_NOW,
      sendEmail: true,

      allReferals: STD_REG_REFERRAL_OPTIONS,
      allReasons: STD_REG_REASON_OPTIONS,
      fieldTypes: { password: 'text', repeatPassword: 'text' },
      STD_REG_FORM_PAY_OPTIONS,
      isPreEnrollLoading: false,
      allSchools: [],
      allPrograms: [],
      countryOptions: [],
      stateOptions: [],
      workExpOptions: [
        { value: '0-1', text: '0-1 year' },
        { value: '1-2', text: '1-2 years' },
        { value: '3-4', text: '3-4 years' },
        { value: '5-6', text: '5-6 years' },
        { value: '7+', text: '7+ years' },
      ],
      workDayOptions: [],
      workTypeOptions: [
        { value: 'part_time', text: 'Part-time' },
        { value: 'full_time', text: 'Full-time' },
        { value: 'not_sure', text: 'Not sure' },
      ],
      workTimeOptions: [
        { value: 'day', text: 'Day' },
        { value: 'evening', text: 'Evening' },
        { value: 'overnight', text: 'Overnight' },
      ],
      workTravelOptions: [],
      facilityTypeOptions: [
        { value: 'adult_day_care', text: 'Adult day care' },
        { value: 'assisted_living', text: 'Assisted living' },
        { value: 'hospital', text: 'Hospital' },
        { value: 'hospice_care', text: 'Hospice care' },
        { value: 'home_care', text: 'Home care' },
        { value: 'longterm_care', text: 'Long-term care' },
        { value: 'other', text: 'Other' },
      ],
      careerPreferences: [],
      profileVisOptions: [
        { value: true, text: 'Active' },
        { value: false, text: 'Paused' },
      ],
      langOptions: [],
      yesNoOptions: YES_NO_OPTIONS,
      genderOptions: GENDER_OPTIONS,
      ethnicityOptions: ETHNICITY_OPTIONS,
      classIdFromPreEnroll: null,
      errorStates: {
        password: null,
        userEmail: null,
        cnaLicenseNo: null,
        phone: null,
      },
      passErrorCode: null,

      isPassCopied: false,
      areSchoolsLoading: false,
      isLogoUploading: false,
      areCareerPrefsLoading: false,

      isUploading: {
        avatar: false,
        resume: false,
      },
      isQueFileUploading: {},
      selectedUploadFile: {
        avatar: null,
        resume: null,
      },
      selectedQueUploadFile: {},
      uploadPercent: { avatar: 0, resume: 0, file_type: {} },
      uploadCancelTokenSource: {
        avatar: null,
        resume: null,
        file_type: {},
      },
      classSch: {},
      isDataLoading: false,
      isClassLoading: false,
      responseData: {
        lang_preferences: null,
        desired_work_type: null,
        desired_work_time: null,
        desired_work_day: null,
        work_travel: null,
        interested_facility_types: null,
        student_ssn: null,
        reference: null,
        registration_reason: null,
        plan_to_pay: null,
        occupation: null,
        gender: null,
        ethnicity: null,
        address: null,
        zipcode: null,
        city: null,
        state: null,
        country: null,
        resume_url: null,
      },
      file_type: null,
      areSectionsLoading: false,
      STATIC_FIELD_TYPE,
      QUES_TYPES,
      sections: [],
      customSectionData: {},
      customSectionValidation: null,
      STD_REG_PAY_OPTIONS,
      reg_desc: null,
      PROGRAM_PAYMENT_OPTIONS,

      selectedSponsorship: null,
      sponsorshipOptions: [],
      showApplication: false,
      applicationRes: {},
      applicationSubmitted: false,
      waiveAppFee: false,

      affId: null,
    };
  },
  validations() {
    const val = {
      registration_reason: {
        required: requiredIf(() => this.responseData.registration_reason === STATIC_FIELD_TYPE.REQUIRED),
      },
      signature: { required },
      ethnicity: {
        required: requiredIf(() => get(this.responseData, 'ethnicity') === STATIC_FIELD_TYPE.REQUIRED),
      },
      ethnicity_other: { required: requiredIf(() => this.ethnicity === 'other') },
      other_registration_reason: { required: requiredIf(() => this.registration_reason === 'other') },
      student: {
        birthday: { required },
        certified_before: { required },
        ged_or_diploma: { required },
        career_service_interest: { required },

        desired_work_type: {
          required: requiredIf(() => this.responseData.desired_work_type === STATIC_FIELD_TYPE.REQUIRED),
        },
        desired_work_time: {
          required: requiredIf(() => this.responseData.desired_work_time === STATIC_FIELD_TYPE.REQUIRED),
        },
        desired_work_day: {
          required: requiredIf(() => this.responseData.desired_work_day === STATIC_FIELD_TYPE.REQUIRED),
        },
        work_travel: { required: requiredIf(() => this.responseData.work_travel === STATIC_FIELD_TYPE.REQUIRED) },

        lang_preferences: {
          required: requiredIf(() => this.responseData.lang_preferences === STATIC_FIELD_TYPE.REQUIRED),
        },
        resume_url: { required: requiredIf(() => this.responseData.resume_url === STATIC_FIELD_TYPE.REQUIRED) },

        address: { required: requiredIf(() => this.responseData.address === STATIC_FIELD_TYPE.REQUIRED) },
        zipcode: { required: requiredIf(() => this.responseData.zipcode === STATIC_FIELD_TYPE.REQUIRED) },
        city: { required: requiredIf(() => this.responseData.city === STATIC_FIELD_TYPE.REQUIRED) },
        state: { required: requiredIf(() => this.responseData.state === STATIC_FIELD_TYPE.REQUIRED) },
        country: { required: requiredIf(() => this.responseData.country === STATIC_FIELD_TYPE.REQUIRED) },
        student_ssn: {
          required: requiredIf(() => this.responseData.student_ssn === STATIC_FIELD_TYPE.REQUIRED),
          // isCorrectFormat: (value) => !value || SSN_FORMAT_REGEX.test(value),
        },

        occupation: { required: requiredIf(() => this.responseData.occupation === STATIC_FIELD_TYPE.REQUIRED) },
      },
      plan_to_pay: { required: requiredIf(() => this.responseData.plan_to_pay === STATIC_FIELD_TYPE.REQUIRED) },
      other_reference: { required: requiredIf(() => this.reference === 'other') },
      reference: { required: requiredIf(() => this.responseData.reference === STATIC_FIELD_TYPE.REQUIRED) },
      interested_facility_types: {
        required: requiredIf(() => this.responseData.interested_facility_types === STATIC_FIELD_TYPE.REQUIRED),
      },
      selectedSponsorship: { required: requiredIf(() => this.plan_to_pay === 'sponsored_employer_agency') },
      //other_interested_facility_types: { required: requiredIf(() => this.interested_facility_types.includes('other')) },
      payment_option: { required: requiredIf(() => get(this.classSch, 'program.tuition_reg_req') === false) },
      user: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        phone: {
          required,
          isCorrectFormat(value) {
            return PHONE_FORMAT_REGEX.test(value);
          },
        },
        avatarUrl: '',
        gender: { required: requiredIf(() => this.responseData.gender === STATIC_FIELD_TYPE.REQUIRED) },
      },
      customSectionData: {},
    };

    return val;
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),

    isFormLoading() {
      return (
        this.isLoading ||
        this.isClassLoading ||
        this.isLogoUploading ||
        this.isDataLoading ||
        this.isPayLoading ||
        this.areProductsLoading ||
        Object.values(this.isUploading).some(val => val) ||
        Object.values(this.isQueFileUploading).some(val => val)
      );
    },

    fullClassLocation() {
      return get(this.classSch, 'class_location')
        ? `${this.classSch.class_location.address}, ${this.classSch.class_location.city}, ${this.classSch.class_location.state} ${this.classSch.class_location.zipcode}, ${this.classSch.class_location.country}`
        : 'N/A';
    },
    totalAmount() {
      return this.cartArr.reduce((total, item) => item.price * item.quantity + total, 0);
    },

    calcServiceFee() {
      // Healthcare Enroll Service Fee = 4% of total transaction fee.
      // Minimum service fee is $2.99

      const serviceFee =
        (this.calcPrgApplicationFee +
          (this.products.length !== 0 ? this.totalAmount : 0) +
          (this.payment_option === STD_REG_PAY_OPTIONS.PAY_NOW ? get(this.classSch, 'program.tuition_fee', 0) : 0)) *
        0.04;

      return serviceFee > STD_REG_MIN_SERVICE_FEE ? serviceFee : STD_REG_MIN_SERVICE_FEE;
    },
    calTotalFee() {
      if (this.payment_option === STD_REG_PAY_OPTIONS.PAY_NOW) {
        return (
          get(this.classSch, 'program.tuition_fee', 0) +
          this.calcPrgApplicationFee +
          (this.products.length !== 0 ? this.totalAmount : 0) +
          this.calcServiceFee
        );
      } else
        return this.calcServiceFee + (this.products.length !== 0 ? this.totalAmount : 0) + this.calcPrgApplicationFee;
    },
    totalTuition() {
      return get(this.classSch, 'program.tuition_fee', 0) + get(this.classSch, 'program.application_fee', 0);
    },

    calcPrgApplicationFee() {
      return this.payment_option === STD_REG_PAY_OPTIONS.FREE_PLAN || this.waiveAppFee
        ? 0
        : get(this.classSch, 'program.application_fee', 0);
    },

    enteredStdAddr() {
      const addr = [this.student.address, this.student.city, this.student.state, this.student.zipcode].filter(e => !!e);

      return addr.join(', ');
    },

    affiliateId() {
      return this.$route.query.affid || this.affId || null;
    },
  },
  watch: {
    classSch: {
      handler(value) {
        if (value && this.getCurrentSchool) {
          this.school = get(this.getCurrentSchool, 'id');
          this.fetchRegistrationForm();
        }
      },
    },
  },
  methods: {
    ...mapActions('student', [
      'getStudentUploadPresignedUrl',
      'createStudent',
      'createRegPaymentIntent',
      'registrationPaymentSuccess',
      'registrationPaymentFailed',
      'getInitiateReg',
    ]),
    ...mapActions('program', ['getAllScholarshipList']),
    ...mapActions('registrationForm', ['getPublicRegistrationForm']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('classes', ['getPublicClass']),
    ...mapActions('sections', ['getPublicSections']),
    ...mapActions('payoutAcc', ['intentPay']),
    ...mapActions('products', ['getPublicProducts']),
    ...mapActions('preEnrollForm', ['getPreEnrollFormObj']),
    formatDateSimple,
    formatFullDate,
    stdPlanToPayOptions,
    changeField() {
      if (this.ssnPassType === 'text') {
        this.ssnPassType = 'password';
        this.visibility = false;
      } else {
        this.ssnPassType = 'text';
        this.visibility = true;
      }
    },

    get,
    setImage(file, image, uploadType = 'images') {
      this.selectedUploadFile[image] = file;
      this.uploadFile(image, uploadType);
    },
    goToHome() {
      this.$router.push({ name: 'home' });
    },
    async fetchPreEnrollForm() {
      this.isPreEnrollLoading = true;
      try {
        const resp = (await this.getPreEnrollFormObj(this.$route.query.pre_enrollment_id)).data;
        this.user.email = resp.email;
        this.user.firstName = resp.first_name;
        this.user.lastName = resp.last_name;
        this.user.phone = resp.phone;

        this.classIdFromPreEnroll = resp.class_enrolled.id;
        this.student.birthday = resp.payload;
        this.user.gender = resp.payload.gender;
        this.student.desired_work_type = resp.payload.desired_work_type;
        this.student.desired_work_time = resp.payload.desired_work_time;
        this.student.desired_work_day = resp.payload.desired_work_day;
        this.student.work_travel = resp.payload.work_travel;
        this.student.lang_preferences = resp.payload.lang_preferences;
        this.student.certified_before = resp.payload.certified_before;
        this.student.resume_url = resp.payload.resume_url;
        this.student.address = resp.payload.address;
        this.student.zipcode = resp.payload.zipcode;
        this.student.city = resp.payload.city;
        this.student.state = resp.payload.state;
        this.student.country = resp.payload.country;
        this.interested_facility_types = resp.payload.interested_facility_types;
        this.student.career_service_interest = resp.payload.career_service_interest;
        this.student.student_ssn = resp.payload.student_ssn;
        this.ethnicity = resp.payload.ethnicity;

        this.student.occupation = resp.payload.occupation;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isPreEnrollLoading = false;
    },
    addToCart(obj, quantity, size) {
      let foundProductIndex = this.cartArr.findIndex(product => product.id === obj.id && product.size === size);
      if (foundProductIndex !== -1) {
        let newQuantity = this.cartArr[foundProductIndex].quantity + quantity;
        this.cartArr[foundProductIndex].quantity = newQuantity;
      } else {
        this.cartArr.push({ ...obj, quantity: quantity, size: size });
      }
    },
    removeFromCart(id) {
      let updatedArr = this.cartArr.filter(x => x.id !== id);
      this.cartArr = updatedArr;
    },
    incrementQuantity(index) {
      this.cartArr[index].quantity += 1;
    },
    decrementQuantity(index) {
      this.cartArr[index].quantity -= 1;
    },
    ApplicationFormResponse({ data, isSubmit }) {
      this.applicationRes = data;
      this.applicationSubmitted = isSubmit;
      if (this.applicationSubmitted && this.applicationRes) {
        this.showProductCard();
      } else {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    showApplicationForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (
          this.selectedSponsorship &&
          this.selectedSponsorship.id != 'other' &&
          this.selectedSponsorship.scholarship_type === SPONSORSHIP_SUBMISSION_TYPES.IN_APP_SUBMISSION
        ) {
          this.showApplication = true;
        } else {
          this.showProductCard();
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
    },
    showProductCard() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.products.length != 0) {
          this.showApplication = false;
          this.showProducts = true;
        } else this.onSubmit();
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
    },
    backFromProductInfo() {
      this.showProducts = false;
    },
    async pay() {
      if (
        (this.payment_option === STD_REG_PAY_OPTIONS.FREE_PLAN && !this.cartArr.length) ||
        (this.payment_option === STD_REG_PAY_OPTIONS.PAY_LATER && !this.cartArr.length && this.waiveAppFee) ||
        (this.payment_option === STD_REG_PAY_OPTIONS.PAY_LATER &&
          !this.cartArr.length &&
          !get(this.classSch, 'program.application_fee'))
      ) {
        window.location.href = `${window.location.origin}/registration?class=${this.classSch.id}&draft=${this.intentDraftId}&checkoutSuccess=1`;
      } else this.$refs.paymentRef.submit();
    },
    async fetchProducts() {
      this.areProductsLoading = true;

      const response = await this.getPublicProducts({ program_id: this.classSch.program.id });
      this.products = response.data;
      // this.products.forEach((product) => {
      //   product['quantity'] = 1;
      // });
      this.areProductsLoading = false;
    },
    async fetchScholarships(params = {}) {
      try {
        const response = await this.getAllScholarshipList({
          program_id: this.classSch.program.id,
          is_active: true,
          in_reg_form: true,
          ...params,
        });

        this.sponsorshipOptions = response.data;
        this.sponsorshipOptions.push({
          title: 'Other',
          id: 'other',
        });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async fetchSections(params = {}) {
      this.areSectionsLoading = true;
      try {
        const res = await this.getPublicSections({
          section_enroll_type: 'enrollment',
          program: this.classSch.program.id,
          ...params,
        });

        const secData = {};
        const queSecUploadFile = {};
        const queFileUploading = {};

        for (const sectionObj of res.data) {
          const quesObj = sectionObj.sectionquestion_set.reduce((obj, item) => {
            if (item.question.type === QUES_TYPES.FILE_TYPE) {
              queSecUploadFile[`sec-${sectionObj.id}-que-${item.question.id}`] = null;
              queFileUploading[`sec-${sectionObj.id}-que-${item.question.id}`] = false;
            }

            return Object.assign(obj, {
              [`question-${item.question.id}`]: null,
            });
          }, {});
          secData[`section-${sectionObj.id}`] = quesObj;
        }

        this.selectedQueUploadFile = queSecUploadFile;
        this.isQueFileUploading = queFileUploading;

        this.customSectionData = secData;
        this.sections = res.data;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areSectionsLoading = false;
    },
    async fetchRegistrationForm() {
      this.isDataLoading = true;
      try {
        const resp = (
          await this.getPublicRegistrationForm({
            params: {
              program: this.classSch.program.id,
              school: this.school,
              enrollment_type: 'enrollment',
            },
          })
        ).data;

        resp.forEach(obj => {
          this.responseData[obj.field_abbr] = obj.status;
        });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isDataLoading = false;
    },
    async fetchClass() {
      this.isClassLoading = true;

      const response = await this.getPublicClass({
        class: this.$route.query.class || this.classIdFromInitiate || this.classIdFromPreEnroll,
      });
      this.classSch = response.data;

      if (!this.$route.query.regId) {
        if (this.classSch.program.allow_pymnt_option !== PROGRAM_PAYMENT_OPTIONS.TUITION_REQ) {
          if (this.classSch.program.allow_pymnt_option === PROGRAM_PAYMENT_OPTIONS.PAY_LATER) {
            this.payment_option = STD_REG_PAY_OPTIONS.PAY_LATER;
          } else {
            this.payment_option = STD_REG_PAY_OPTIONS.PAY_NOW;
          }
        }
      }

      if (get(this.getCurrentSchool, 'pp_registration_desc')) {
        this.reg_desc = this.getCurrentSchool.pp_registration_desc
          .replaceAll(
            '{{application_fee}}',
            this.$n(get(this.classSch, 'program.application_fee', 0), 'currency', 'en-US')
          )
          .replaceAll('{{tuition_fee}}', this.$n(get(this.classSch, 'program.tuition_fee', 0), 'currency', 'en-US'));
      }
      if (!get(this.classSch, 'program.application_fee')) {
        this.waiveAppFee = true;
      }
      this.isClassLoading = false;
    },
    backToForm() {
      this.showPaymentDetail = false;
      this.elementsOptions['clientSecret'] = '';
      this.isPaymentDetailsLoading = false;
      this.confirmParams['return_url'] = '';
      this.registrationPaymentFailed({ draft_id: this.intentDraftId });
    },
    async onSubmit() {
      this.isLoading = true;
      // this.$v.$touch();
      // if (!this.$v.$invalid) {

      try {
        const sectionsArr = [];
        Object.entries(this.customSectionData).map(section => {
          Object.entries(section[1]).map(question => {
            sectionsArr.push({
              section: parseInt(section[0].split('-')[1]),
              question: parseInt(question[0].split('-')[1]),
              response: { data: question[1] },
            });
          });
        });
        this.student.birthday = this.student.birthday ? formatToAPIDate(moment(this.student.birthday)) : null;
        this.payment_option = this.$route.query.regId
          ? this.payment_option
          : get(this.classSch, 'program.tuition_reg_req') === false
          ? this.payment_option
          : STD_REG_PAY_OPTIONS.PAY_NOW;

        const data = {
          ...(this.selectedSponsorship &&
            this.selectedSponsorship.scholarship_type !== SPONSORSHIP_SUBMISSION_TYPES.IN_APP_SUBMISSION && {
              scholarship_name: this.selectedSponsorship.title,
            }),
          school: this.school,
          registration: {
            program: this.classSch.program.id,
            class_enrolled: this.classSch.id,
            plan_to_pay: this.plan_to_pay,
            registration_reason: this.registration_reason,
            ...(this.other_registration_reason && { other_registration_reason: this.other_registration_reason }),
            signature: this.signature,
            payment_option: this.payment_option,
          },
          reference: this.reference,
          ...(this.other_reference && { other_reference: this.other_reference }),
          ethnicity: this.ethnicity,
          ...(this.ethnicity_other && { ethnicity_other: this.ethnicity_other }),
          interested_facility_types: this.interested_facility_types,

          ...this.student,
          diploma_date: this.student.diploma_date ? formatToAPIDate(moment(this.student.diploma_date)) : null,
          products_bought: this.products.length > 0 ? this.cartArr : [],
          student_question_response: sectionsArr,
          user: {
            email: this.user.email,
            first_name: titleize(this.user.firstName),
            last_name: titleize(this.user.lastName),
            password: generator.generate({ length: 10, numbers: true, strict: true }),
            phone: this.user.phone,
            bio: this.user.bio,
            avatar_url: this.user.avatarUrl,
            gender: this.user.gender,
          },
          reg_id: this.$route.query.regId || null,
          pre_enrollment_id: this.$route.query.pre_enrollment_id || null,

          ...(this.applicationSubmitted && this.applicationRes),

          ...(this.affiliateId && { aff_id: this.affiliateId }),
        };

        // Adding payment intent related info for displaying payment element

        this.isPaymentDetailsLoading = true;

        const paymentIntent = await this.createRegPaymentIntent(data);

        this.intentDraftId = paymentIntent.draft_id;
        const draftId = paymentIntent.draft_id;
        if (paymentIntent.intent) {
          this.elementsOptions['clientSecret'] = paymentIntent.intent;

          this.confirmParams[
            'return_url'
          ] = `${window.location.origin}/registration?class=${this.classSch.id}&draft=${draftId}&checkoutSuccess=1`;
        }

        this.showRefundPolicy = true;
      } catch (err) {
        this.makeToast({
          variant: 'danger',
          msg: 'Cannot register you in this class. Please contact administrator.',
          autoHideDelay: 5000,
        });
        this.isPaymentDetailsLoading = false;
        captureException(err);
      }
      // } else {
      //   this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      // }
      this.isLoading = false;
    },

    acceptRefund() {
      if (this.acceptRefundPolicy) {
        this.showPaymentDetail = true;
        this.showRefundPolicy = false;
      }
    },

    async uploadFile(
      fileType,
      uploadType = 'documents',
      profileUrlField = 'resume_url',
      section = null,
      question = null
    ) {
      let file;

      if (fileType === QUES_TYPES.FILE_TYPE) {
        this.uploadCancelTokenSource[fileType][`sec-${section}-que-${question}`] = axios.CancelToken.source();
        this.isQueFileUploading[`sec-${section}-que-${question}`] = true;
        file = this.selectedQueUploadFile[`sec-${section}-que-${question}`];
      } else {
        this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
        this.isUploading[fileType] = true;
        file = this.selectedUploadFile[fileType];
      }

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: file.name,
          content_type: file.type,
          upload_type: uploadType,
        });

        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: file,
          config: {
            onUploadProgress: function(progressEvent) {
              const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              if (fileType === QUES_TYPES.FILE_TYPE) {
                this.uploadPercent[fileType][`sec-${section}-que-${question}`] = percentage;
              } else {
                this.uploadPercent[fileType] = percentage;
              }
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
        if (fileType === 'avatar') {
          this.user.avatarUrl = urlResp.upload_url.split('?')[0];
        } else if (fileType === 'file_type') {
          this.customSectionData[`section-${section}`][`question-${question}`] = urlResp.upload_url.split('?')[0];
          this.file_type = urlResp.upload_url.split('?')[0];
        } else this.student[profileUrlField] = urlResp.upload_url.split('?')[0];
      } catch (error) {
        if (fileType === QUES_TYPES.FILE_TYPE) {
          this.selectedQueUploadFile[`sec-${section}-que-${question}`] = null;
        } else {
          this.selectedUploadFile[fileType] = null;
        }
      }
      if (fileType === QUES_TYPES.FILE_TYPE) {
        this.uploadCancelTokenSource[fileType][`sec-${section}-que-${question}`] = null;
        this.uploadPercent[fileType][`sec-${section}-que-${question}`] = 0;
        this.isQueFileUploading[`sec-${section}-que-${question}`] = false;
      } else {
        this.uploadCancelTokenSource[fileType] = null;
        this.uploadPercent[fileType] = 0;
        this.isUploading[fileType] = false;
      }
    },
    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, state => ({
          value: state.name,
          text: state.name,
        }))
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, country => ({
          value: country.name,
          text: country.name,
        }))
      );

      this.workDayOptions = map(moment.weekdays(), day => ({ value: day.toLowerCase(), text: day }));

      this.workTravelOptions = getWorkTravelOptions();

      this.langOptions = getLangOptions();
    },
    async fetchInitiateDraft() {
      this.isClassLoading = true;

      try {
        const response = await this.getInitiateReg({ regId: this.$route.query.regId });

        this.user.email = response.data.user.email;
        this.user.firstName = response.data.user.first_name;
        this.user.lastName = response.data.user.last_name;
        this.user.phone = response.data.user.phone;
        this.user.gender = response.data.user.gender;

        this.payment_option = response.data.registration.payment_option;
        this.waiveAppFee = response.data.waive_app_fee;
        this.classIdFromInitiate = response.data.registration.class_enrolled;
        this.student.birthday = response.data.birthday;
      } catch (err) {
        this.goToHome();
      }

      this.isClassLoading = false;
    },
  },
  async created() {
    this.fillInOptions();

    if (this.$route.query.checkoutSuccess) {
      this.isClassLoading = true;
      const draftId = this.$route.query.draft;
      try {
        await this.registrationPaymentSuccess({ draft_id: draftId });
        this.registeredSuccessfull = true;
        this.$router.push({ name: 'class-registration', query: { class: this.classSch.id, success: true } });
      } catch (err) {
        await this.registrationPaymentFailed({ draft_id: draftId });
        this.registeredUnsuccessfull = true;
        this.$router.push({ name: 'class-registration', query: { class: this.classSch.id, failed: true } });
        // this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isClassLoading = false;
    } else {
      if (this.$route.query.regId) {
        await this.fetchInitiateDraft();
      }
      if (this.$route.query.pre_enrollment_id) {
        await this.fetchPreEnrollForm();
      }
      await this.fetchClass();
      this.fetchSections();
      this.fetchProducts();
      this.fetchScholarships();
    }
  },
};
</script>

<style></style>
